import React, { useState } from "react";

const LoginPage = () => {
  const [isRegistering, setIsRegistering] = useState(false);

  return (
    <div style={{ padding: "20px", textAlign: "center" }}>
      <h1>{isRegistering ? "ثبت‌نام" : "ورود"}</h1>

      {/* فرم ورود */}
      {!isRegistering && (
        <form>
          <div>
            <label>ایمیل:</label>
            <input type="email" placeholder="ایمیل خود را وارد کنید" />
          </div>
          <div>
            <label>رمز عبور:</label>
            <input type="password" placeholder="رمز عبور خود را وارد کنید" />
          </div>
          <button type="submit">ورود</button>
          <p>
            هنوز حساب کاربری ندارید؟{" "}
            <a href="#" onClick={() => setIsRegistering(true)}>
              ثبت‌نام کنید
            </a>
          </p>
        </form>
      )}

      {/* فرم ثبت‌نام */}
      {isRegistering && (
        <form>
          <div>
            <label>نام:</label>
            <input type="text" placeholder="نام خود را وارد کنید" />
          </div>
          <div>
            <label>ایمیل:</label>
            <input type="email" placeholder="ایمیل خود را وارد کنید" />
          </div>
          <div>
            <label>رمز عبور:</label>
            <input type="password" placeholder="رمز عبور خود را وارد کنید" />
          </div>
          <div>
            <label>آدرس:</label>
            <input type="text" placeholder="آدرس خود را وارد کنید" />
          </div>
          <div>
            <label>تلفن:</label>
            <input type="text" placeholder="شماره تلفن خود را وارد کنید" />
          </div>
          <button type="submit">ثبت‌نام</button>
          <p>
            حساب کاربری دارید؟{" "}
            <a href="#" onClick={() => setIsRegistering(false)}>
              ورود
            </a>
          </p>
        </form>
      )}
    </div>
  );
};

export default LoginPage;
