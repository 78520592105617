import React from 'react';
import '../styles/Footer.css';  // مسیر صحیح فایل CSS

const toPersianDigits = (num) => {
  return num.toString().replace(/\d/g, (digit) => "۰۱۲۳۴۵۶۷۸۹"[digit]);
};

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-contact">
        <h3>تماس با ما</h3>
        <p>
          آدرس: جاده قدیم کرج-قزوین، نرسیده به پاسگاه پلیس راه کمالشهر، خیابان صفا، بعد از کوچه دوم، درب اول
        </p>
        <p>تلفن: {toPersianDigits("5-34022653-026")}</p>
        <p>
          ایمیل: <a href="mailto:NooshAlborz@gmail.com">NooshAlborz@gmail.com</a>
        </p>
      </div>
      <div className="footer-copyright">
        <p>تمامی حقوق این سایت متعلق به شرکت نوش البرز است.</p>
      </div>
    </footer>
  );
};

export default Footer;