// import React from 'react';
// import '../styles/Slider.css';  // مسیر صحیح فایل CSS
// import drink1 from '../assets/images/drink1.jpg';
// import drink2 from '../assets/images/drink2.jpg';
// import drink3 from '../assets/images/drink3.jpg';
// import machine1 from '../assets/images/machine1.jpg';
// import machine2 from '../assets/images/machine2.webp';

// const Slider = () => {
//   return (
//     <div className="slider">
//       <img src={drink1} alt="نوشابه 1" />
//       <img src={drink2} alt="نوشابه 2" />
//       <img src={drink3} alt="نوشابه 3" />
//       <img src={machine1} alt="دستگاه نوشابه ساز 1" />
//       <img src={machine2} alt="دستگاه نوشابه ساز 2" />
//     </div>
//   );
// };


// export default Slider;

import React, { useState, useEffect } from "react";
import "../styles/Slider.css";

const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [
    { src: "../assets/images/drink1.jpg", alt: "نوشابه 1" },
    { src: "../assets/images/drink2.jpg", alt: "نوشابه 2" },
    { src: "../assets/images/drink3.jpg", alt: "نوشابه 3" },
    { src: "../assets/images/machine1.jpg", alt: "نوشابه ساز 1" },
    { src: "../assets/images/machine2.webp", alt: "نوشابه ساز 2" },
  ];

  // تغییر اسلاید به صورت خودکار
  useEffect(() => {    
    const interval = setInterval(() => {
      nextSlide();
    }, 10000); // هر دو ثانیه یکبار تصویر عوض می‌شود
    return () => clearInterval(interval); // پاک‌سازی تایمر
  }, [currentIndex]);

  // تابع برای رفتن به اسلاید بعدی
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // تابع برای رفتن به اسلاید قبلی
  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div className="slider-container">
      <div className="slider">
        {images.map((image, index) => (
          <div
            key={index}
            className={`slide ${index === currentIndex ? "active" : ""}`}
          >
            <img src={image.src} alt={image.alt} />
          </div>
        ))}
        <button className="prev" onClick={prevSlide}>
          &#10094;
        </button>
        <button className="next" onClick={nextSlide}>
          &#10095;
        </button>
      </div>
    </div>
  );
};

export default Slider;