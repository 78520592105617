/// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'; // ایمپورت useLocation
import Navbar from './components/Navbar';
import LoginPage from "./components/LoginPage";
import HomePage from "./components/HomePage";
import Cart from './components/Cart';
import Slider from './components/Slider';
import Articles from './components/Articles';
import Contact from './components/Contact';
import FooterInfo from './components/FooterInfo';
import Footer from './components/Footer';

import './styles/App.css';
import './styles/fonts.css';
import './styles/main.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const App = () => {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/login" element={<LoginPage />} />
        </Routes>
        <MainContent />
        <FooterInfo /> {/* اطلاعات بالا‌ی فوتر */}
        <Footer /> {/* فوتر اصلی */}
      </div>
    </Router>
  );
};

const MainContent = () => {
  const location = useLocation(); // استفاده از useLocation

  return (
    <>
      {/* اسلایدر و مقالات فقط در صفحه اصلی نمایش داده شوند */}
      {location.pathname === "/" && (
        <>
          <Slider />
          <Articles />
        </>
      )}
    </>
  );
};

export default App;