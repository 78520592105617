import React from 'react';
import { Link } from "react-router-dom";
import '../styles/Navbar.css';  // مسیر صحیح فایل CSS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';


const Navbar = () => {
  const cartCount = 0; // تعداد آیتم‌های داخل سبد خرید

  return (
    <nav className="navbar">
      {/* اقدامات: ورود/ثبت‌نام و سبد خرید */}
      <div className="navbar-actions">
        {/* دکمه ورود/ثبت‌نام */}
        <Link to="/login">
          <button className="btn login-btn">ورود/ثبت‌نام</button>
        </Link>
        <div className="cart-icon">
          <FontAwesomeIcon icon={faShoppingCart} size="lg" />
          <span className="cart-count">{cartCount}</span>
        </div>
      </div>

      {/* لینک‌های ناوبری */}
      <ul className="navbar-links">
        <li><a href="#contact">تماس با ما</a></li>
        <li><a href="#about-us">درباره ما</a></li>
        <li><a href="#sales-centers">مراکز فروش</a></li>
        <li><a href="#blog">بلاگ</a></li>
        <li><a href="#catalog">کاتالوگ محصولات</a></li>
        <li><a href="#services">خدمات</a></li>
        <li><a href="#products">محصولات</a></li>
      </ul>

      {/* لوگو در سمت راست */}
      <div className="navbar-logo">
        <a href="/">نوش البرز</a>
      </div>
    </nav>
  );
};

export default Navbar;