import React from 'react';
import '../styles/Articles.css'; // فایل CSS مربوط به مقالات

const Articles = () => {
  return (
    <section className="articles-section">
      <h2>مقالات مرتبط با نوشابه و دستگاه‌های نوشابه‌ساز</h2>
      <div className="articles-container">
        <div className="article">
          <h3>تاریخچه نوشابه</h3>
          <p>
            نوشابه یکی از محبوب‌ترین نوشیدنی‌های گازدار جهان است. تاریخچه این نوشیدنی به قرن ۱۸ بازمی‌گردد، زمانی که برای اولین بار به‌عنوان یک داروی گوارشی معرفی شد.
          </p>
          <a href="#">ادامه مطلب</a>
        </div>
        <div className="article">
          <h3>مزایا و معایب استفاده از دستگاه‌های نوشابه‌ساز</h3>
          <p>
            دستگاه‌های نوشابه‌ساز به شما امکان می‌دهند نوشیدنی‌های گازدار را در خانه تهیه کنید. اما آیا این دستگاه‌ها ارزش خرید دارند؟
          </p>
          <a href="#">ادامه مطلب</a>
        </div>
        <div className="article">
          <h3>مواد تشکیل‌دهنده نوشابه</h3>
          <p>
            نوشابه‌ها معمولاً از آب گازدار، شکر، اسیدهای خوراکی و طعم‌دهنده‌ها تشکیل می‌شوند. در این مقاله به جزئیات ترکیبات آن‌ها می‌پردازیم.
          </p>
          <a href="#">ادامه مطلب</a>
        </div>
      </div>
    </section>
  );
};

export default Articles;
