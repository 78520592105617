import React from "react";

const HomePage = () => {
  return (
    <div style={{ padding: "20px", textAlign: "center" }}>
      <h1>!به سایت نوش البرز خوش آمدید</h1>
      <p>.این صفحه اصلی سایت شما است. محتوای دلخواه خود را اینجا اضافه کنید</p>
    </div>
  );
};

export default HomePage;
