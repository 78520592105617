// src/components/FooterInfo.js
import React from "react";
import "../styles/FooterInfo.css";
// import originalImage from "../assets/original.png"; // تصویر نماد اصالت کالا
// import returnImage from "../assets/return.png"; // تصویر بازگشت کالا
// import supportImage from "../assets/support.png"; // تصویر پشتیبانی
// import deliveryImage from "../assets/delivery.png"; // تصویر تحویل اکسپرس

const FooterInfo = () => {
  return (
    <div className="footer-info">
      {/* بخش آیکون‌ها */}
      <div className="footer-icons-section">
      <div className="icon-item">
          <i style={{backgroundPosition:'-100px -474px'}} ></i>
          <p>تضمین بهترین قیمت</p>
        </div>
        <div className="icon-item">
        <i style={{backgroundPosition:'-315px -474px'}} ></i>
          <p>تحویل اکسپرس</p>
        </div>
        <div className="icon-item">
          <i style={{backgroundPosition:'-152px -474px'}} ></i>
          <p>ضمانت اصل بودن کالا</p>
        </div>
        <div className="icon-item">
        <i style={{backgroundPosition:'-258px -474px'}} ></i>
          <p>پرداخت در محل</p>
        </div>
        <div className="icon-item">
        <i style={{backgroundPosition:'-205px -474px'}} ></i>
          <p>هفت روز ضمانت بازگشت</p>
        </div>        
      </div>

      {/* بخش لینک‌ها */}
      <div className="footer-links-section">
        {/* بخش آیکون‌های شبکه اجتماعی */}
      <div className="social-media">
        <h4>!همراه ما باشید</h4>
        <div className="social-icons">
          <i style={{backgroundPosition:'-310px -544px'}} ></i>
          <i style={{backgroundPosition:'-344px -544px'}} ></i>
          <i style={{backgroundPosition:'-374px -544px'}} ></i>
          <i style={{backgroundPosition:'-407px -544px'}} ></i>
          <i style={{backgroundPosition:'-440px -544px'}} ></i>
          <i style={{backgroundPosition:'-473px -544px'}} ></i>
        </div>
        {/* بخش فرم ثبت ایمیل */}
      <div className="newsletter">
        <p>با ثبت ایمیل، از جدیدترین تخفیف‌ها باخبر شوید</p>
        <div className="email-form">
          <input type="email" placeholder="ایمیل شما" />
          <button>ثبت</button>
        </div>
      </div>
      </div>      
      <div className="footer-section">
          <h4>راهنمای خرید از نوش البرز</h4>
          <ul>
            <li>نحوه ثبت سفارش</li>
            <li>رویه ارسال سفارش</li>
            <li>شیوه‌های پرداخت</li>
          </ul>
        </div>        
        <div className="footer-section">
          <h4>خدمات مشتریان</h4>
          <ul>
            <li>پاسخ به پرسش‌های متداول</li>
            <li>رویه‌های بازگرداندن کالا</li>
            <li>شرایط استفاده</li>
            <li>حریم خصوصی</li>
            <li>گزارش باگ</li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>با نوش البرز</h4>
          <ul>
            <li>اتاق خبر نوش البرز</li>
            <li>فروش در نوش البرز</li>
            <li>فرصت‌های شغلی</li>
            <li>گزارش تخلف در نوش البرز</li>
            <li>تماس با نوش البرز</li>
          </ul>
        </div>        
      </div>
    </div>
  );
};

export default FooterInfo;
